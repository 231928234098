<template>
    <div id="wrapper" class="container">
        <div class="loader" v-if="loading"></div>

        <div v-if="!loading">
            <ul role="list-items" class="list-group" v-if="data.length > 0">
                <li
                    class="list-group-item"
                    v-for="(item, index) in data"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'Contract Details',
                            params: { contractId: encrypt(item.id) },
                        }"
                        >{{ item.property_details.title }} Contract - ({{ item["id"] }})  </router-link
                    >
                </li>
            </ul>
            <div v-if="data.length == 0">No contracts yet</div>
        </div>
    </div>
</template>

<script>
import { getContractByUser } from "@/api/contracts";
import { getInfo } from "@/api/user";
export default {
    name: "UserContractDetails",
    data() {
        return {
            user_id: null,
            data: null,
            loading: true,
        };
    },
    created() {
        this.loading = true;
        getInfo().then((res) => {
            this.user_id = res.data.user.id;
            setTimeout(() => {
                getContractByUser(this.user_id).then((res) => {
                    this.loading = false;
                    // console.log(this.loading, "=-=-=");
                    this.data = res.data.data;
                });
            }, 1000);
        });
    },
    methods: {
        encrypt(id) {
            let encryptId = btoa(id);
            return encryptId;
        },
    },
};
</script>
<style scoped lang="scss">
.list-group-item a {
    display: block;
    color:#000;
}
.list-group-item {
    background-color: white !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border: unset !important;
    margin-bottom: 15px;
    border-radius: 4px;
    width: calc(50% - 15px);
    margin-right:15px;
    padding:12px 12px;
    @media (min-width:1921px) {
        margin-right:0.781vw;
        width: calc(50% -  0.781vw);
        padding: 1.042vw;
        font-size: 0.833vw;
        margin-bottom: 0.781vw;
    }
    @media (max-width:767px) {
        margin-right:0;
        width: 100%;
    }
}
.list-group-item:nth-child(2n){
    margin-left: auto;
    margin-right: 0;
}
</style>
<style>
html:not([dir="rtl"]) .list-group {
    padding-left: 0;
}
.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
.list-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
}
dl,
ol,
ul {
    margin-top: 0;
}
.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
</style>
